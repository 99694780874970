import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import clsx from 'clsx';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  IonButton,
  IonImg,
  IonRadio,
  IonRadioGroup,
  IonSpinner,
  UseIonRouterResult,
} from '@ionic/react';

import Input from '@/components/atoms/Input.tsx';
import CategoriesIcon from '@/components/icons/common/CategoriesIcon.tsx';
import ChevronDownIcon from '@/components/icons/common/ChevronDownIcon.tsx';
import CloseIcon from '@/components/icons/common/CloseIcon.tsx';
import PlusIcon from '@/components/icons/common/PlusIcon.tsx';
import SearchRequestIcon from '@/components/icons/common/SearchRequestIcon.tsx';
import { useModalContext } from '@/contexts/ModalContext.tsx';
import { usePhotoActions } from '@/hooks/usePhotoActions.tsx';
import CategoryTreeSelectContent from '@/modules/categories/components/CategoryTreeSelectContent.tsx';
import { ICategory } from '@/modules/categories/types/ICategory.ts';
import {
  ISearchCreateRequest,
  requestCreateSchema,
  useSearchCreate,
} from '@/modules/searches/api/useSearchCreate.ts';
import { IEnumTradeType, IEnumTradeTypeKeys } from '@/types/enums/IEnumTradeType.ts';
import analytics from '@/utils/analytics';

export default function SearchCreateBox({
  className,
  formClassName,
  isHome,
  router,
}: {
  className?: string;
  formClassName?: string;
  isHome?: boolean;
  router: UseIonRouterResult;
}) {
  const { t } = useTranslation();
  const { closeModal, closeSecondaryModal, setSecondaryModalProps } = useModalContext();
  const photoActions = usePhotoActions();

  const [selectedCategory, setSelectedCategory] = useState<ICategory>();
  // const [query, setQuery] = useState('');

  // const [queryDebounce] = useDebounceValue(query, 400);

  const form = useForm<ISearchCreateRequest>({
    defaultValues: { categoryId: '', photos: [], search: '', tradeTypes: [IEnumTradeType.RETAIL] },
    resolver: zodResolver(requestCreateSchema),
  });

  // const searchQueryAutocomplete = useSearchQueryAutocomplete({ q: queryDebounce });
  // const autocompletes = searchQueryAutocomplete.data?.data;

  const searchCreate = useSearchCreate();

  useEffect(() => {
    form.setValue('categoryId', selectedCategory?.id || '');
  }, [selectedCategory]);

  return (
    <div className={clsx('relative z-10 max-w-full rounded-xl px-4', className)}>
      <form
        className={clsx(
          'flex flex-col gap-4 overflow-visible rounded-20 lg:gap-5',
          isHome ? 'bg-white shadow-[0_0_25px_0_rgba(151,151,151,0.1)]' : '',
          formClassName,
          // 0px 0px 25px 0px #9797971A
        )}
        onSubmit={form.handleSubmit((values) => {
          if (!searchCreate.isPending) {
            searchCreate
              .mutateAsync({
                ...values,
                photos: photoActions.photos
                  ?.map(({ photo }) => photo)
                  .filter((photo) => !!photo) as string[],
              })
              .then((res) => {
                analytics.log('c_product_search_request', {
                  category_id: values.categoryId,
                  category_name: selectedCategory?.name,
                  product_name: values.search,
                });

                closeSecondaryModal();
                closeModal();

                router.push(`/searches/${res.data?.id}`);
              });
          }
        })}
      >
        {isHome && (
          <img
            alt="hero-person"
            className="absolute -top-46 right-0 -z-10 h-56 lg:hidden"
            src="/images/home/home-person-christmas.png"
          />
        )}

        <Controller
          control={form.control}
          name="categoryId"
          render={({ field, fieldState }) => (
            <Input
              errorText={fieldState.error?.message}
              leftIcon={<CategoriesIcon aria-hidden="true" className="text-gobazar-800" />}
              onClick={() =>
                setSecondaryModalProps({
                  children: (
                    <CategoryTreeSelectContent
                      onClose={closeSecondaryModal}
                      onSelect={(category) => {
                        setSelectedCategory(category);
                        closeSecondaryModal();
                      }}
                      selected={selectedCategory}
                    />
                  ),
                })
              }
              onIonInput={(e) => {
                field.onChange(e.target?.value as string);
              }}
              placeholder={t('categories.choose')}
              readonly
              rightIcon={<ChevronDownIcon aria-hidden="true" className="text-gobazar-800" />}
              type="text"
              value={selectedCategory?.name || ''}
            />
          )}
        />

        <Controller
          control={form.control}
          name="search"
          render={({ field, fieldState }) => (
            <Input
              // autocompletes={autocompletes}
              errorText={fieldState.error?.message}
              // isAutocompleteLoading={searchQueryAutocomplete.isLoading || queryDebounce != query}
              leftIcon={<SearchRequestIcon aria-hidden="true" className="text-gobazar-800" />}
              // onAutocompleteClick={(value) => {
              //   field.onChange(value);
              //   setQuery(value);
              // }}
              onIonInput={(e) => {
                field.onChange(e.target?.value as string);
                // setQuery(e.target?.value as string);
              }}
              placeholder={t('home.inputProductName')}
              type="text"
              value={field.value}
            />
          )}
        />

        <Controller
          control={form.control}
          name="tradeTypes"
          render={({ field, fieldState }) => (
            <div className="flex flex-col gap-1">
              <IonRadioGroup className="flex flex-row gap-6 px-1.5" value={field.value?.[0]}>
                {Object.keys(IEnumTradeType).map((type) => (
                  <IonRadio
                    className="truncate text-18 lg:text-20 [&.radio-checked::part(container)]:border-4 [&.radio-checked::part(container)]:border-gobazar-800 [&.radio-checked::part(container)]:transition-[border-color] [&.radio-checked::part(container)]:duration-500 [&.radio-checked::part(container)]:lg:border-[5px] [&::part(container)]:h-4 [&::part(container)]:w-4 [&::part(container)]:border [&::part(container)]:border-neutral-300 [&::part(container)]:lg:h-5 [&::part(container)]:lg:w-5 [&::part(mark)]:hidden"
                    justify="start"
                    key={type}
                    labelPlacement="end"
                    mode="md"
                    onClick={() => {
                      field.onChange([type]);
                    }}
                    value={type}
                  >
                    {t(`tradeTypes.${type as IEnumTradeTypeKeys}Request`)}
                  </IonRadio>
                ))}
              </IonRadioGroup>
              <p className="mb-0 !h-auto text-14 text-red-500">
                {fieldState.error?.message ? t('forms.required') : ''}
              </p>
            </div>
          )}
        />

        <IonButton
          className="mx-auto h-10 w-3/5 min-w-[210px] tracking-wide lg:h-14 lg:w-2/3 [&::part(native)]:rounded-16 [&::part(native)]:border [&::part(native)]:p-1"
          disabled={photoActions.photos.length >= 3 || photoActions.isLoading}
          expand="block"
          fill="outline"
          mode="md"
          onClick={() => {
            analytics.log('c_click_search_add_photo');
            photoActions.pickGalleryImages();
          }}
        >
          {photoActions.isLoading ? (
            <IonSpinner name="crescent" />
          ) : (
            <div className="flex flex-row items-center justify-between gap-1 text-14 font-6 normal-case text-gobazar lg:text-18">
              <PlusIcon className="h-4 w-4 lg:h-5 lg:w-5" />
              {t('requests.addPhoto')}
            </div>
          )}
        </IonButton>

        {!!photoActions.photos.length && (
          <div className="my-1 flex flex-row gap-4">
            {photoActions.photos.map((photo, idx) => {
              return (
                <div
                  className="relative h-20 w-20 overflow-hidden rounded-2xl border border-neutral-300"
                  key={`photo-${idx}`}
                >
                  <IonImg className="h-20 w-20 rounded-xl object-cover" src={photo.webviewPath} />
                  <CloseIcon
                    className="absolute right-1.5 top-1.5 h-5 w-5 cursor-pointer rounded-full bg-white p-0.5 text-neutral-600 shadow-2xl"
                    onClick={() => {
                      if (!photoActions.isLoading) {
                        photoActions.deleteUploadedPhoto(idx);
                      }
                    }}
                    strokeWidth={3}
                  />
                </div>
              );
            })}
          </div>
        )}

        <div className="flex flex-col gap-2">
          <IonButton
            className="h-14 text-16 font-8 lg:text-20 [&::part(native)]:bg-gobazar"
            disabled={photoActions.isLoading}
            expand="block"
            type="submit"
          >
            {searchCreate.isPending ? <IonSpinner /> : t('requests.findProduct')}
          </IonButton>

          {!isHome && (
            <IonButton
              className="text-14 font-5 !text-[#04041560] lg:text-18"
              disabled={photoActions.isLoading}
              expand="block"
              fill="clear"
              size="small"
            >
              {t('common.cancel_action')}
            </IonButton>
          )}
        </div>
      </form>
    </div>
  );
}
