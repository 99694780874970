import { lazy, Suspense } from 'react';
import { Redirect } from 'react-router';
import { Route } from 'react-router-dom';

import { IonApp, IonRouterOutlet, IonTabs, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import BottomBar from '@/components/atoms/BottomBar.tsx';
import LoadingSuspense from '@/components/atoms/LoadingSuspense.tsx';
import NotFoundPage from '@/components/organisms/NotFoundPage.tsx';
import HomePage from '@/modules/home/pages/HomePage.tsx';

import './assets/theme/global.scss';

setupIonicReact({ mode: 'ios' });

const StorePage = lazy(() => import('@/modules/stores/pages/StorePage.tsx'));
const SearchesPage = lazy(() => import('@/modules/searches/pages/SearchesPage.tsx'));
const SearchPage = lazy(() => import('@/modules/searches/pages/SearchPage.tsx'));
const OfferPage = lazy(() => import('@/modules/offers/pages/OfferPage.tsx'));
const StoreProductPage = lazy(() => import('@/modules/products/pages/ProductPage.tsx'));

export default function App() {
  return (
    <IonApp>
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet animated>
            <Route component={NotFoundPage} exact path="*" />

            <Redirect from="/requests*" to="/searches*" />

            <Route
              exact
              path="/searches"
              render={(props) => (
                <Suspense fallback={<LoadingSuspense />}>
                  <SearchesPage {...props} />
                </Suspense>
              )}
            />
            <Route
              exact
              path="/searches/:searchId"
              render={(props) => (
                <Suspense fallback={<LoadingSuspense />}>
                  <SearchPage {...props} />
                </Suspense>
              )}
            />

            <Route
              exact
              path="/searches/:searchId/offer/:offerId"
              render={(props) => (
                <Suspense fallback={<LoadingSuspense />}>
                  <OfferPage {...props} />
                </Suspense>
              )}
            />

            <Route
              exact
              path={['/store/:storeSlug']}
              render={(props) => (
                <Suspense fallback={<LoadingSuspense />}>
                  <StorePage {...props} />
                </Suspense>
              )}
            />

            <Route
              exact
              path={['/store/:storeSlug/product/:productId']}
              render={(props) => (
                <Suspense fallback={<LoadingSuspense />}>
                  <StoreProductPage {...props} />
                </Suspense>
              )}
            />

            <Route component={HomePage} exact path="/home" />
            <Route component={HomePage} exact path="/" />
          </IonRouterOutlet>

          <BottomBar />
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
}
