import { useLayoutEffect, useState } from 'react';

import { IonTabBar, IonTabButton, useIonRouter } from '@ionic/react';

import HomeIcon from '@/components/icons/common/HomeIcon.tsx';
import SearchBubbleIcon from '@/components/icons/common/SearchBubbleIcon.tsx';

export default function BottomBar() {
  const router = useIonRouter();
  const [role, setRole] = useState('section');

  useLayoutEffect(() => {
    setTimeout(() => {
      setRole('navigation');
    }, 50);
  }, []);

  return (
    <IonTabBar className="flex-row-reverse lg:py-1" mode="md" role={role} slot="bottom">
      <IonTabButton
        aria-label="Request History"
        className="!opacity-1 text-[#21252947] [&.tab-selected]:text-gobazar-400"
        href="/searches"
        // @ts-ignore
        role="tablist"
        tab="/searches"
      >
        <SearchBubbleIcon className="h-9 w-9 lg:h-11 lg:w-11" />
      </IonTabButton>

      <IonTabButton
        aria-label="GoBazar"
        className="text-[#21252947] [&.tab-selected]:text-gobazar-400"
        href="/home"
        // @ts-ignore
        role="tablist"
        routerOptions={{ unmount: true }}
        selected={router.routeInfo.pathname == '/' || router.routeInfo.pathname == '/home'}
        tab="home"
      >
        <HomeIcon className="h-9 w-9 lg:h-11 lg:w-11" />
      </IonTabButton>
    </IonTabBar>
  );
}
